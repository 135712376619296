import funds from "@/apis/funds.js";
import axios from "axios";
export const oss = {
  data() {
    return {
      ossObj: {
        //oss签名
        expire: "",
        accessid: "",
      },
    };
  },
  methods: {
    overSize() {
      this.$toast("图片不能大于5M");
      return;
    },
    async doUpload(file, type) {
      //使用oss
      this.$toast({
        type: "loading",
        overlay: true,
        message: "上传中...",
        duration: 0,
      });
      //try {
      let nowTime = Date.parse(new Date()) / 1000;
      // 判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
      if (this.ossObj.expire < nowTime + 3) {
        this.ossObj = await this.getCallbackSign();
      }
      if (this.ossObj.accessid) {
        this.ossUpload(file, type);
      } else {
        this.$toast.clear();
        this.$toast("上传失败");
      }
      // } catch (error) {
      //   console.log(11);
      //   this.$toast.clear();
      //   this.$toast("上传失败");
      // }
    },
    ossUpload(file, type) {
      console.log(type);
      //获取文件后缀
      let fileInfo = file.name.split(".");
      //获取文件名和目录
      let filename = this.random_string() + "." + fileInfo[fileInfo.length - 1];
      var formData = new FormData();
      //注意formData里append添加的键的大小写
      formData.append("key", this.ossObj.dir + filename); //存储在oss的文件路径
      formData.append("OSSAccessKeyId", this.ossObj.accessid); //accessKeyId
      formData.append("policy", this.ossObj.policy); //policy
      formData.append("Signature", this.ossObj.signature); //签名
      formData.append("callback", this.ossObj.callback); //签名'callback': callbackbody,
      formData.append("success_action_status", 200); //成功后返回的操作码
      //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
      formData.append("file", file);

      const options = {
        method: "POST",
        data: formData,
        url: this.ossObj.host,
      };
      axios(options)
        .then((res) => {
          console.log(res);
          if (res.data.IsSuccess) {
            type
              ? this.setImgUrl(res.data.Data.url, type)
              : this.setImgUrl(res.data.Data.url);
            this.$toast.clear();
          } else {
            this.getImgUrl(this.ossObj.dir + filename, type);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast("上传失败！");
          console.log(err);
        });

      // $.ajax({
      //   url: this.ossObj.host,
      //   type: "POST",
      //   data: formData,
      //   cache: false,
      //   contentType: false,
      //   processData: false,
      //   success: (res) => {
      //     if (res.IsSuccess) {
      //       this.setImgUrl(res.Data.url, type);
      //       this.$toast.clear();
      //     } else {
      //       this.getImgUrl(this.ossObj.dir + filename, type);
      //     }
      //   },
      //   error: function (data) {},
      // });
    },
    //获取图片url
    getImgUrl(path, type) {
      funds
        .getPictureUrl({ path: path })
        .then((res) => {
          this.$toast.clear();
          if (res.IsSuccess) {
            type ? this.setImgUrl(res.Data, type) : this.setImgUrl(res.Data);
          } else {
            this.$toast(res.Data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast("获取图片地址失败");
          this.$toast.clear();
        });
    },
    random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    //获取OSS验证签名
    async getCallbackSign() {
      return await funds.getCallbackSign();
    },
  },
};
